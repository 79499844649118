import './App.scss';
import { useEffect } from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigate,
  defer
} from 'react-router-dom';
import axios from 'axios';
import * as firebase from 'firebase/app';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import '@fontsource/sora';
import '@fontsource/roboto';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AuthProvider } from './hooks/use-auth';

Sentry.init({
  dsn:
    process.env.REACT_APP_EMULATORS === 'enabled'
      ? null
      : 'https://9bcaf50d2701110378f635e41369c511@o1349717.ingest.us.sentry.io/4507500533841920',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigate,
      createRoutesFromElements,
      matchRoutes
    })
  ],
  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

ReactGA.initialize('G-399V8WWJGS');

// Need to initialize Firebase before features can be used
if (process.env.REACT_APP_EMULATORS === 'enabled') {
  firebase.initializeApp(
    {
      apiKey: 'AIzaSyBZY-QIv21zsgLLTL8e0DRpItT71uLtCWY',
      storageBucket: 'hawx-services.appspot.com'
    },
    '[DEFAULT]'
  );
  const auth = getAuth();
  if (!auth.config.emulator) {
    connectAuthEmulator(auth, `http://${process.env.REACT_APP_LOCAL_HOST}:9099`);
  }
} else {
  firebase.initializeApp({
    apiKey: 'AIzaSyBZY-QIv21zsgLLTL8e0DRpItT71uLtCWY',
    authDomain: 'hawx-services.firebaseapp.com',
    databaseURL: 'https://hawx-services.firebaseio.com',
    projectId: 'hawx-services',
    storageBucket: 'hawx-services.appspot.com',
    messagingSenderId: '377539872826',
    appId: '1:377539872826:ios:b0c79617cf06ec2d1bcd03'
  });
}

const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        lazy={async () => ({ Component: (await import('./screens/Home')).default })}
      />
      <Route
        path="/login"
        lazy={async () => ({
          Component: (await import('./screens/internal/InternalLogin')).default
        })}
      />
      <Route
        path="internal"
        lazy={async () => ({ Component: (await import('./screens/internal')).Internal })}
      >
        <Route
          path="start-signup"
          lazy={async () => ({ Component: (await import('./screens/internal')).StartSignup })}
        />
        <Route
          path="blackbird"
          lazy={async () => ({ Component: (await import('./screens/internal')).BlackbirdAdmin })}
        />
        <Route
          path="price-floor"
          lazy={async () => ({ Component: (await import('./screens/internal')).PriceOverride })}
        />
        <Route
          path="routes"
          lazy={async () => ({ Component: (await import('./screens/internal')).RouteAvailability })}
        />
        <Route
          path="rsp-connection"
          lazy={async () => ({ Component: (await import('./screens/internal')).RspConnection })}
        />
        <Route
          path="merge-customer"
          lazy={async () => ({ Component: (await import('./screens/internal')).MergeCustomers })}
        />
      </Route>
      <Route
        path="/:appointmentID"
        lazy={async () => ({ Component: (await import('./screens/ClientReport')).default })}
      />
      <Route
        path="/:appointmentID/welcome-letter"
        lazy={async () => ({ Component: (await import('./screens/WelcomeLetterScreen')).default })}
      />
      <Route
        path="/welcome-letter"
        lazy={async () => ({ Component: (await import('./screens/sales')).SalesWelcomeLetter })}
      />
      <Route
        path="/welcome-letter/:customerID"
        lazy={async () => ({ Component: (await import('./screens/sales')).SalesWelcomeLetter })}
      >
        <Route
          path="overview"
          lazy={async () => ({ Component: (await import('./screens/sales')).Overview })}
        />
        <Route
          path="verify-contact-info"
          lazy={async () => ({ Component: (await import('./screens/sales')).ReviewCustomerInfo })}
        />
        <Route
          path="hawx-warranty"
          lazy={async () => ({ Component: (await import('./screens/sales')).WarrantyAndFinish })}
        />
      </Route>
      <Route
        path="/sign-up"
        lazy={async () => ({ Component: (await import('./screens/sales')).Signup })}
      />
      <Route
        path="/sign-up/:linkId"
        lazy={async () => ({ Component: (await import('./screens/signup')).Signup })}
        loader={({ params }) =>
          defer({
            signupData: axios
              .get(`${process.env.REACT_APP_SALES_API_URL}/signup/${params.linkId}`)
              .then((result) => result.data)
          })
        }
      >
        <Route
          path="services"
          lazy={async () => ({ Component: (await import('./screens/signup')).PackageSelection })}
        />
        <Route
          path="supplemental-questions"
          lazy={async () => ({ Component: (await import('./screens/signup')).Questions })}
        />
        <Route
          path="pricing"
          lazy={async () => ({ Component: (await import('./screens/signup')).PriceAdjustment })}
        />
        <Route
          path="payment"
          lazy={async () => ({ Component: (await import('./screens/signup')).Payment })}
        />
        <Route
          path="payment/financed"
          lazy={async () => ({ Component: (await import('./screens/signup')).Financed })}
        />
        <Route
          path="payment/payment-profile"
          lazy={async () => ({ Component: (await import('./screens/signup')).PaymentProfile })}
        />
        <Route
          path="payment/payment-profile/new"
          lazy={async () => ({ Component: (await import('./screens/signup')).NewPaymentProfile })}
        />
        <Route
          path="review"
          lazy={async () => ({ Component: (await import('./screens/signup')).Review })}
        />
        <Route
          path="contract"
          lazy={async () => ({ Component: (await import('./screens/signup')).Contract })}
        />
        <Route
          path="scheduling"
          lazy={async () => ({ Component: (await import('./screens/signup')).Scheduling })}
        />
        <Route
          path="confirmation"
          lazy={async () => ({ Component: (await import('./screens/signup')).Confirmation })}
        />
      </Route>
      <Route
        path="/:appointmentID/change-billing"
        lazy={async () => ({ Component: (await import('./screens/billing')).ChangePayment })}
      >
        <Route
          path="choose-method"
          lazy={async () => ({ Component: (await import('./screens/billing')).BillingPhoneScreen })}
        />
        <Route
          path="verification"
          lazy={async () => ({
            Component: (await import('./screens/billing')).BillingVerification
          })}
        />
        <Route
          path="payment-info"
          lazy={async () => ({ Component: (await import('./screens/billing')).BillingPaymentInfo })}
        />
      </Route>
    </>
  )
);

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
