import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import OktaSignIn from '@okta/okta-signin-widget';
import axios from 'axios';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const store = useMemo(
    () => ({
      user,
      setUser,
      userInfo,
      setUserInfo
    }),
    [user, userInfo]
  );

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const authContext = useContext(AuthContext);
  const { user, setUser, userInfo, setUserInfo } = authContext;

  const makeApiRequest = useCallback(
    async (options) => {
      const token = await user.getIdToken();

      return axios({
        headers: {
          Authorization: `Bearer ${token}`
        },
        ...options
      });
    },
    [user]
  );

  const logInWithOktaIdToken = useCallback(async (oktaIdToken) => {
    const loginResponse = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/login`,
      data: {
        idToken: oktaIdToken
      }
    });
    await signInWithCustomToken(getAuth(), loginResponse.data.token);
  }, []);

  const refreshUserInfo = useCallback(async () => {
    const userInfoResponse = await makeApiRequest({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/user/info`
    });
    const { oktaId, username, firstName, lastName, permissions } = userInfoResponse.data;
    setUserInfo({ oktaId, username, firstName, lastName, permissions });
  }, [setUserInfo, makeApiRequest]);

  const logOut = useCallback(() => {
    const oktaSignIn = new OktaSignIn({
      issuer: 'https://hawxservices.okta.com',
      clientId: '0oaba44cmrEyxeVdW697',
      redirectUri: `${window.location.origin}/login/callback`,
      scopes: ['openid', 'profile', 'email']
    });
    oktaSignIn.authClient.signOut();
    signOut(getAuth());
    setUser(null);
  }, [setUser]);

  return {
    user,
    setUser,
    userInfo,
    makeApiRequest,
    logInWithOktaIdToken,
    refreshUserInfo,
    logOut
  };
}

export { AuthProvider, useAuth };
